<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import coingeckoImg from '~/assets/img/coingecko.svg'
import type { PriceData } from '~/store'
import { formatAmount, formatAndTrimAmount, formatMoney, formatPct } from '~/utils'

const props = defineProps({
  priceData: {
    type: Object as PropType<PriceData>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  symbol: {
    type: String,
    required: true,
  },
  coingeckoSymbol: {
    type: String,
    required: true,
  },
  loading: Boolean,
})

const isPriceData = computed(() => props.priceData)
const growIsNegative = computed(() => isPriceData.value ? isPriceData.value?.changePercent24 < 0 : true)
const price = computed(() => isPriceData.value ? formatMoney(isPriceData.value?.price) : 0)
const grow = computed(() => isPriceData.value ? formatPct.format(isPriceData.value?.changePercent24 / 100) : 0)
const last24 = computed(() => isPriceData.value ? formatAndTrimAmount(Math.abs(isPriceData.value?.change24), 2) : 0)
const value = computed(() => isPriceData.value ? formatAmount(isPriceData.value?.vol24, 2).replace('G', 'B') : 0)
</script>

<template>
  <div class="price-card">
    <div class="price-card__title">
      <div>{{ $t('other.price') }} ({{ name }}) ({{ symbol }})</div>
      <q-btn
        :href="`https://www.coingecko.com/en/coins/${coingeckoSymbol}`"
        class="price-card__link"
        color="white"
        round
        target="_blank"
        type="a"
        unelevated
      >
        <img :src="coingeckoImg" class="q-icon" alt="price card icon">
      </q-btn>
    </div>
    <price-card-skeleton v-if="loading" />
    <div v-else>
      <div class="price-card__main">
        <div class="price-card__main__price">
          ${{ price }}
        </div>
        <div
          class="price-card__main__grow"
          :class="{ 'price-card__main__grow--negative': growIsNegative }"
        >
          {{ grow }}
        </div>
      </div>
      <div class="price-card__additional">
        <div class="price-card__additional__price">
          {{ $t('other.last24h') }} {{ growIsNegative ? '-' : '+' }}${{ last24 }}
        </div>
        <div v-if="symbol === 'SOL'" class="price-card__additional__value">
          ${{ value }}
        </div>
      </div>
    </div>
  </div>
</template>
