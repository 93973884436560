<template>
  <div>
    <div class="price-card__main">
      <q-skeleton height="34px" width="118px" class="q-mb-xs" />
      <q-skeleton height="22px" width="44px" />
    </div>
    <div class="price-card__additional">
      <div class="price-card__additional__price">
        <q-skeleton height="13px" width="118px" />
      </div>
    </div>
  </div>
</template>
